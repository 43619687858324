<template>
  <div>
    <DetalhesCartaModal
      v-if="showDetalhesCartaModal"
      :cartaId="cartaIdSelected"
      @closeModal="closeDetalhesCartaModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent"
      ></div>
      <div class="card-body">
        <ListCartasSite :cartas="cartas" @detalhesCarta="detalhesCarta" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListCartasSite from '@/components/backoffice/cartas/ListCartasSite.vue'
import DetalhesCartaModal from '@/components/backoffice/cartas/DetalhesCartaModal.vue'

export default {
  name: 'CartasSite',

  components: {
    LoadingData,
    ListCartasSite,
    DetalhesCartaModal,
  },

  data() {
    return {
      title: 'Cartas Contempladas',
      loadData: true,
      showDetalhesCartaModal: false,
      cartas: null,
      cartaIdSelected: null,
      segmentos: this.$route.params.segmentos,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getCartas() {
      try {
        this.cartas = await this.$store.dispatch(
          'getCartasSite',
          this.segmentos,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    detalhesCarta(cartaId) {
      this.cartaIdSelected = cartaId || null
      this.showDetalhesCartaModal = true
    },

    closeDetalhesCartaModal(loadListCartas) {
      if (loadListCartas) {
        this.getCartas()
      }
      this.showDetalhesCartaModal = false
    },
  },

  mounted() {
    this.getCartas()
  },
}
</script>
