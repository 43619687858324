import { dateTimeSqlToDateTimeBR } from './date'

const createdUpdatedUser = (userName, date) => {
  return userName !== null && userName !== ''
    ? `${userName} em ${dateTimeSqlToDateTimeBR(date)}`
    : '-'
}

const statusTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 1:
      return '<span class="badge badge-success">Ativo</span>'
    case 0:
      return '<span class="badge badge-danger">Inativo</span>'
    default:
      return '<span class="badge badge-secondary">Indefinido</span>'
  }
}

const statusCartaTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 1:
      return '<span class="badge badge-success">Disponível</span>'
    case 2:
      return '<span class="badge badge-danger">Reservada</span>'
    case 3:
      return '<span class="badge badge-primary">Vendida</span>'
    default:
      return '<span class="badge badge-secondary">Indefinido</span>'
  }
}

const statusProjectTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 0:
      return '<span class="badge badge-danger">Reprovado</span>'
    case 1:
      return '<span class="badge badge-warning">Aguardando Aprovação</span>'
    case 2:
      return '<span class="badge badge-primary">Em Andamento</span>'
    case 3:
      return '<span class="badge badge-success">Concluído</span>'
    default:
      return '<span class="badge badge-secondary">Indefinido</span>'
  }
}

const moneyToCurrencyBr = (value) => {
  if (value !== null && value !== '') {
    const money = parseFloat(value)
    return money.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }
  return value
}

const stringParcelas = (value) => {
  let parcela = ''
  for (let i = 0; i < value.length; i += 1) {
    const valorParcela = value[i].valor
    parcela += `${value[i].parcelas} X ${moneyToCurrencyBr(valorParcela)}<br>`
  }
  return parcela
}

export {
  createdUpdatedUser,
  statusTranslate,
  statusProjectTranslate,
  moneyToCurrencyBr,
  statusCartaTranslate,
  stringParcelas,
}
