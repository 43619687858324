<template>
  <b-modal
    id="modalDetalhesCarta"
    title="Carta contemplada"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="form-row">
        <div class="col-sm-12">
          <h5>{{ carta.codigo }}</h5>
          <h5>
            {{ carta.segmento_descricao }} -
            {{ carta.administradora_descricao }}
          </h5>
        </div>
      </div>
      <hr />
      <div class="form-row">
        <div class="col-sm-12">
          <label>Valor do crédito: </label><br />
          <h5>{{ moneyToCurrencyBr(carta.valor_credito) }}</h5>
        </div>
      </div>
      <div class="form-row">
        <div class="col-sm-12">
          <label>Valor da entrada: </label><br />
          <h5>{{ moneyToCurrencyBr(carta.valor_entrada) }}</h5>
        </div>
      </div>
      <div class="form-row">
        <div class="col-sm-12">
          <label>Data de vencimento: </label><br />
          <h5>{{ dateSqlToDateBR(carta.vencimento) }}</h5>
        </div>
      </div>
      <div class="form-row">
        <div class="col-sm-12">
          <label>Parcelas: </label><br />
          <h5><span v-html="stringParcelas(carta.parcelas)" /></h5>
        </div>
      </div>
      <div class="form-row">
        <div class="col-sm-12">
          <h5><span v-html="statusCartaTranslate(carta.status)" /></h5>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button type="submit" class="btn btn-primary" title="Tenho interesse">
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>Tenho interesse</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {
  statusCartaTranslate,
  stringParcelas,
  moneyToCurrencyBr,
} from '@/utils/strings'
import { dateSqlToDateBR } from '@/utils/date'

export default {
  name: 'CreateEditCartaModal',

  props: {
    cartaId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      carta: {
        codigo: '',
        administradora_id: '',
        segmento_id: '',
        valor_credito: '',
        valor_entrada: '',
        vencimento: '',
        segmento_descricao: '',
        administradora_descricao: '',
        status: '',
        parcelas: '',
      },
      disableButton: false,
      loadDataEditing: false,
      statusCartaTranslate,
      moneyToCurrencyBr,
      stringParcelas,
      dateSqlToDateBR,
    }
  },

  methods: {
    async getCartaInfoSite() {
      try {
        this.loadDataEditing = true
        this.carta = await this.$store.dispatch(
          'getCartaInfoSite',
          this.cartaId,
        )
        this.$bvModal.show('modalDetalhesCarta')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },
  },

  mounted() {
    this.getCartaInfoSite()
  },
}
</script>
