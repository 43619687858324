<template>
  <div>
    <div class="mb-3 mr-3 ml-3">
      <div class="form-row justify-content-center form-inline">
        <div class="form-group mr-3" style="width: 200px">
          <multiselect
            v-model="segmentosSelecionados"
            :options="segmentos"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Selecione os segmentos"
            label="descricao"
            track-by="descricao"
            :preselect-first="false"
            :selectLabel="'Selecione'"
            :deselectLabel="'Pressione enter para remover'"
            :selectedLabel="'Já Selecionado'"
          >
            <template v-if="segmentosSelecionados.length > 0" slot="selection"
              >{{ segmentosSelecionados.length }} segmentos
              selecionados</template
            >
          </multiselect>
        </div>
        <div class="form-group">
          <button
            type="submit"
            class="btn btn-primary"
            title="Buscar"
            @click="getCartasFiltro()"
          >
            <span>Filtrar</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Mostrar
            <b-form-select
              v-model="table.perPage"
              size="sm"
              :options="table.pageOptions"
            />
            registros
          </label>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="dataTables_filter text-md-right mb-3 mr-3 ml-3">
          <label class="d-inline-flex align-items-center">
            Pesquisar:
            <b-form-input
              type="search"
              class="form-control form-control-sm ml-2"
              placeholder="Pesquisar..."
              v-model="table.filter"
            />
          </label>
        </div>
      </div>
    </div>
    <div class="table-responsive mb-0">
      <b-table
        :items="cartas"
        :fields="table.fields"
        responsive="sm"
        :per-page="table.perPage"
        :current-page="table.currentPage"
        :sort-by.sync="table.sortBy"
        :sort-desc.sync="table.sortDesc"
        :filter="table.filter"
        :filter-included-fields="table.filterOn"
        @filtered="onFiltered"
      >
        <template #cell(valor_credito)="row">
          <span v-html="row.value"></span>
        </template>
        <template #cell(valor_entrada)="row">
          <span v-html="row.value"></span>
        </template>
        <template #cell(vencimento)="row">
          <span v-html="row.value"></span>
        </template>
        <template #cell(parcelas)="row">
          <span v-html="row.value"></span>
        </template>
        <template #cell(status)="row">
          <span v-html="row.value"></span>
        </template>

        <template #cell(actions)="row">
          <button
            type="button"
            class="btn btn-outline-primary"
            title="Saiba mais"
            @click="$emit('detalhesCarta', row.item.id)"
          >
            Saiba mais
          </button>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col d-inline-flex align-items-center">
        Quantidade de registros: {{ cartas.length }}
        <span v-if="table.filter != null && table.filter != ''">
          &nbsp;(Filtrados: {{ table.totalRows }})
        </span>
      </div>
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination
              v-model="table.currentPage"
              :total-rows="table.totalRows"
              :per-page="table.perPage"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  statusCartaTranslate,
  stringParcelas,
  moneyToCurrencyBr,
} from '@/utils/strings'
import { dateSqlToDateBR } from '@/utils/date'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ListCartasSite',

  components: {
    Multiselect,
  },

  props: {
    cartas: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      table: {
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: 'name',
        sortDesc: false,
        fields: [
          { key: 'segmento_descricao', label: 'Segmento', sortable: true },
          {
            key: 'administradora_descricao',
            label: 'Administradora',
            sortable: true,
          },
          {
            key: 'valor_credito',
            label: 'Valor do crédito',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value) => {
              return moneyToCurrencyBr(value)
            },
          },
          {
            key: 'valor_entrada',
            label: 'Valor da Entrada',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value) => {
              return moneyToCurrencyBr(value)
            },
          },
          {
            key: 'parcelas',
            label: 'Parcelas',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value) => {
              return stringParcelas(value)
            },
          },
          {
            key: 'vencimento',
            label: 'Vencimento',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value) => {
              return dateSqlToDateBR(value)
            },
          },
          {
            key: 'status',
            label: 'Status',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            tdClass: 'text-center',
            formatter: (value) => {
              return statusCartaTranslate(value)
            },
          },
          {
            key: 'actions',
            label: 'Ações',
            thStyle: { width: '130px' },
          },
        ],
      },
      segmentos: null,
      segmentosSelecionados: [],
      valorSelecionado: 5000,
      ticks: [
        {
          value: 5000,
          label: 'R$ 5.000',
        },
        {
          value: 250000,
          label: 'R$ 250.000',
        },
        {
          value: 500000,
          label: 'R$ 500.000',
        },
        {
          value: 750000,
          label: 'R$ 750.000',
        },
        {
          value: 1000000,
          label: 'R$ 1.000.000',
        },
      ],
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getCartasFiltro() {
      try {
        if (this.segmentosSelecionados.length > 0) {
          const arr = Object.keys(this.segmentosSelecionados)
          this.cartas = await this.$store.dispatch(
            'getCartasSite',
            arr.join(','),
          )
          this.loadData = false
        } else {
          this.$root.$bvToast.toast(
            'Selecione pelo menos um segmento para buscar cartas disponíveis',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    formatMoney(value) {
      this.value = parseFloat(value).toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'BRL',
      })
    },

    onFiltered(filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },

    async getSegmentosFiltro() {
      try {
        this.segmentos = await this.$store.dispatch('getSegmentosFiltro')
        this.segmentosSelecionados = this.segmentos
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },
  },

  mounted() {
    this.getSegmentosFiltro()
    this.table.totalRows = this.cartas.length
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
